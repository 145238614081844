<aside class="banner-sy" *ngIf="showMaintanceBanner">
	<div class="banner-content">
		<span>Bookee platform is under scheduled maintenance. Please check back after some time. <a href="https://status.bookeeapp.com/incident/132486">(Read more)</a></span>
	</div>
</aside>
<div class="appLoader active" *ngIf="loaderOn">
	<div class="loaderBox">

		<div class="logo-inside"></div>
	</div>
</div>
<div [ngClass]="{'auth-log-container': !resetOn && !firstPhase, 'auth-search-container': !resetOn && firstPhase  }"
	*ngIf="!loaderOn">
	<div class="logo-container" >
		<a target='_blank' href="{{ website_url }}">
			<img src="../../../../assets/img/bookeelogo.svg" style="width:112px;" alt="Software for fitness studio"></a>
	</div>

	<div class="col-lg-12 auth-form-right" *ngIf="!resetOn && firstPhase">
		<div *ngIf="already_logged_in" style="background-color:white;" class="appLoader active">
			<div class="loaderBox"></div>
		</div>
		<div *ngIf="!already_logged_in" class="auth-log-form form-fitday">
            <div class="form-message error-banner" *ngIf="error.throw == 4">
				<p class="message-content" [class.error-bg]="actualError">
					{{error.msg}}
				</p>
			</div>
			<div class="form-top">
				<p>Welcome back!</p>
			</div>

			<div class="client-search-container">
				<div class="client-search-field">
					<span class="client-search-bar-icon">
						<img src="../../../../assets/img/sy-sb-search.svg" />
					</span> <input type="text" pattern="" id="client-search-input" [class.active]="header_search!=''"
						[(ngModel)]="header_search" (ngModelChange)="fetchResults($event)"
						(keyup)="changeHoverResults($event)" placeholder="Your business name" autocomplete="off" />
				</div>
				<aside class="client-search-result" *ngIf="header_search.length>1">
					<ul class="client-list-container" *ngIf="!client_results">
						<li class="client-search-contact-result searching">
							<div class="spinner-border spinner-border-sm" role="status">
								<span class="sr-only">Searching...</span>
							</div>
						</li>
					</ul>

					<ul class="client-list-container" *ngIf="(client_results)?client_results.length==0:false">
						<li class="client-search-contact-result searching">
							No results
						</li>
					</ul>

					<ul *ngIf="client_results?client_results.length>0:false" #header_results_ul
						class="client-list-container">
						<li *ngFor="let client of client_results" (mouseover)="focusResult($event)"
							(click)="setId(client)" class="client-search-contact-result">
							<div class="clients-img" *ngIf="client.image_url">
								<img [src]="client.image_url" alt="Clients image">
							</div>
							<div class="clients-img" *ngIf="!client.image_url">
								<p class="clients-initial">
									{{client.name.split(" ")[0].slice(0,1).toUpperCase()}}{{(client.name.split(" ")[1]) ? client.name.split(" ")[1].slice(0,1).toUpperCase() : ''}}
								</p>
							</div>
							<div class="clients-list">{{client.name.toLowerCase()}}</div>

						</li>
					</ul>
				</aside>
			</div>
		</div>


	</div>
	<div class="col-lg-12" *ngIf="!resetOn && !firstPhase">
		<div *ngIf="already_logged_in" style="background-color:white;" class="appLoader active">
			<div class="loaderBox"></div>
		</div>

		<div *ngIf="!already_logged_in" class="auth-log-form form-fitday">
			<div class="form-message" *ngIf="resetPassword">
				<p class="message-content">
				</p>
			</div>

			<div class="form-message error-banner" *ngIf="error.throw == 1">
				<p class="message-content" [class.error-bg]="actualError">
					{{error.msg}}
				</p>
			</div>

			<div class="form-message error-banner error-danger" *ngIf="error.throw == 2">
				<p class="message-content" [class.error-bg]="actualError">
					{{error.msg}}
				</p>
            </div>

			<div class="form-top">
				<p>Log in</p>
				<p>to {{selectedClient.name}}
                    <span class="different-business" (click)="firstPhase = true">
                        Select different business ?
                    </span>
                </p>
			</div>

			<div class="form-field auth-log-fields">
				<label>Email</label>
				<input type="text" class="signup-email" autocomplete="off" placeholder="Email"
					(keyup.enter)="loginUser()" [(ngModel)]="client.email" #email="ngModel"
					[ngClass]="{'invalid': formValidate('customer-email') && (email.dirty || email.touched || validationCheck)}">
				<div *ngIf="formValidate('customer-email') && (email.dirty || email.touched || validationCheck)"
					class="form-error-msg">Please
					enter valid email</div>
			</div>
			<div class="form-field auth-log-fields auth-pass" [ngClass]="isPasswordVisible ? 'password-visible' : 'password-obfuscated'">
				<label>Password</label>
				<input [type]="isPasswordVisible ? 'text' : 'password'" class="signup-password" autocomplete="off" [(ngModel)]="client.password"
					(keyup.enter)="loginUser()" placeholder="Password" #password="ngModel"
					[ngClass]="{'invalid': formValidate('customer-password') && (password.dirty || password.touched || validationCheck )}">
				<div *ngIf="formValidate('customer-password') && (password.dirty || password.touched || validationCheck)"
					class="form-error-msg">Oops! Your password is less than 6 characters</div>
				<img [src]="isPasswordVisible ? '../../../../assets/img/password-eye.svg' : '../../../../assets/img/eye-off.svg'" class="password-after" (click)="isPasswordVisible = !isPasswordVisible" alt="show password" />
			</div>
			<div class="form-field auth-log-fields forgot-pass">
				<a (click)="resetOn = true; validationFields.reset_email = false;">Forgot your password? </a>
			</div>
            <!-- <re-captcha
                (resolved)="resolved($event)"
                (error)="onError($event)"
                errorMode="handled"
                siteKey="6Lej804aAAAAALwPyapUF5VdKBFPlhqzESIvfq6z"
                ></re-captcha> -->
			<div class="form-button dis-btn">
				<input type="button" (click)="loginUser()" (keyup.enter)="loginUser()" value="LOG IN"
					id="btn-login-default"
					[disabled]="((validationFields.customer_email) && (validationFields.customer_password) && validCaptcha) ? false : true">
			</div>

		</div>
	</div>
	<div class="col-lg-12" style="margin-top:160px; background-color: white;" *ngIf="resetOn">
		<div class="auth-log-form form-fitday">
            <div class="form-message error-banner error-danger" style="margin-top:50px;" *ngIf="error.throw == 3">
				<p class="message-content" [class.error-bg]="actualError">
					{{error.msg}}
				</p>
			</div>
			<div class="form-top">
				<p>Reset Password</p>
				<p>Return to<a (click)="resetOn = false; error.throw = false; error.msg =''">login</a></p>
			</div>

			<div class="form-field auth-log-fields">
				<label>Email</label>
				<input type="text" class="signup-email" autocomplete="off" placeholder="Email" [(ngModel)]="reset.email"
					#resetEmail="ngModel"
					[ngClass]="{'invalid': formValidate('reset-email') && (resetEmail.dirty || resetEmail.touched)}">
				<div *ngIf="formValidate('reset-email') && (resetEmail.dirty || resetEmail.touched )"
					class="form-error-msg">Oops!
					Please enter valid email</div>
			</div>

			<div class="form-button">
				<button type="button" id="btn-login-default" (click)="forgotPassword()">SEND RESET INSTRUCTIONS</button>
			</div>
		</div>
	</div>
	<app-cookie-banner></app-cookie-banner>
</div>
