 <div class="banner-wrap" *ngIf="checkCookieStatus === null || checkCookieStatus === undefined">
    <div class="banner-container">
        <div class="privacy-text">
            We use cookies to enhance your product experience. <br>
            By continuing to use our product, we’ll assume you are happy with it.
        </div>

        <div class="button-wrap">
            <button type="submit" class="accept" (click)="onSetCookieStatus(true)">Yes, I’m happy!</button>
        </div>
    </div>

    <div class="cancel-wrap" (click)="onSetCookieStatus(false)">
        <img src="../../../../assets/img/cross-gray.svg" alt="">
    </div>
</div>