import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from "@angular/router";
import { ServerAuthService } from "../../../common/server-auth";
import { GeneralServices } from "../../../common/general-services";
import posthog from 'posthog-js';
import { debounce } from "app/decorators/debounce.decorator";


@Component({ selector: "app-auth-login", templateUrl: "./auth-login.component.html", styleUrls: ["./auth-login.component.scss"] })
export class AuthLoginComponent implements OnInit,
  OnDestroy {
  // @ViewChild('ajax_loader') ajax_loader;
  validationCheck: boolean = false;
  loaderOn: boolean = false;
  resetOn: boolean = false;
  forgotPasswordMsg: boolean = false;
  already_logged_in = true;
  client: any = {
    email: "",
    password: "",
    client_id: ""
  };
  validationFields: any = {
    customer_email: false,
    customer_password: false,
    reset_email: false
  };
  reset: any = {
    email: ""
  };
  error: any = {
    throw: false,
    msg: ""
  };
  actualError: any = false;
  resetPassword: any = false;
  signup_url: any = "https://bookeeapp.com/sign-up";
  website_url: any = "https://www.bookeeapp.com/";
  firstPhase: any = true;
  isSearch: boolean = false;
  header_search: any = "";
  client_results: any = null;
  center_id: any;
  searchFilter: any = {
    filterBy: {
      query: null
    },
    sortBy: {},
    page: 1,
    include: "address"
  };
  selectedClient: any;
  validCaptcha: any = true;
  showMaintanceBanner : boolean = false;
  isPasswordVisible = false

  @ViewChild("header_results_ul") header_results_ul;

  constructor(public meta: Meta, public title: Title, private route: ActivatedRoute, private router: Router, private generalFunctions: GeneralServices, private http: ServerAuthService) {
    this.meta.updateTag({ name: 'description', content: "The ultimate fitness studio CRM to manage scheduling, build marketing funnels, create billing cycles and scale your business" });
    this.title.setTitle("Login To Bookee CRM | Client Management For Fitness Businesses");
   }
  ngOnInit() {
    this.loaderOn = true;
    if (JSON.parse(localStorage.getItem("currentUser"))) {
      this.router.navigate(["/client/create-account"]);
      this.firstPhase = false;
    } else {

      // Onboarding without email verification
      if (this.route.snapshot.queryParams['token']) {
        //Logout if already logged in
        if (localStorage.getItem('currentUser')) {
          this.http.logout().subscribe(success => { }, error => {
            this.generalFunctions.openToast(error.message, 5000, "error");
          });
        }
        localStorage.setItem("currentUser", JSON.stringify({
          "token": this.route.snapshot.queryParams['token']
        }))

        this.http.getData('auth/user').subscribe(success => {
          const role = JSON.stringify(success.logged_user.roles);
          localStorage.setItem("currentUser", JSON.stringify({
            token: success.access_token,
            client_id: success.client_id,
            roles: role,
            name: success.logged_user.first_name,
            email: success.logged_user.email,
            businessName: success.client_name,
            setup_completed: success.logged_user.setup_completed,
            setup_details_completed: success.logged_user.setup_details_completed,
            onboarding_completed: success.logged_user.onboarding_completed,
            intercomSecret: success.logged_user.secret,
            is_verified: success.logged_user.is_verified
          }));
          localStorage.setItem('client', JSON.stringify({
            image_url: null,
            name: success.client_name,
            client_url: null,
            client_id: success.client_id
          }))
          this.router.navigate(["/client/create-account"]);
          this.firstPhase = false;

        }, error => {
          console.log("An error occured while retrieving user data")
        })
      } else {
        this.firstPhase = true;
        this.already_logged_in = false;
      }
    }

    this.loaderOn = false;
    if (window.location.origin !== "https://crm.bookeeapp.com") {
      this.signup_url = "https://bookeeapp.com/sign-up";
      this.website_url = "https://bookeeapp.com";
    }

    this.route.queryParams.subscribe(params => {
      // (params['passwrdReset']) ? this.error.throw = 1 : {};
      if (params["passwordReset"]) {
        this.error.throw = 4;
        this.error.msg = "You have changed your password successfully. Please login to continue";
      }
      if (params["setupCompleted"]) {
        this.error.throw = 1;
        this.error.msg = "Email already verified, login to continue";
      }
      if (params["invalidToken"]) {
        this.error.throw = 3;
        this.error.msg = "Token invalid. Please request new password link below.";
        this.resetOn = true;
      }
    });
  }
  ngOnDestroy() { }

  initializePostHog() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const client = JSON.parse(localStorage.getItem('client'));
    posthog.identify(currentUser.email, {
      email: currentUser.email,
      org: client.client_url,
    });

    posthog.group('company', client.client_url, {
      company_name: currentUser?.businessName
    });
  }

  loginUser() {
    this.validationCheck = true;
    if (!this.validationFields.customer_email || !this.validationFields.customer_password) {
      this.generalFunctions.openToast("Please fill in required fields", 5000, "error");
      return;
    }
    if (!JSON.parse(localStorage.getItem("client"))["client_id"]) {
      this.generalFunctions.openToast("The business you have selected has no id", 5000, "error");
      return;
    } else {
      this.client.client_id = JSON.parse(localStorage.getItem("client"))["client_id"];
    }

    this.loaderOn = true;
    this.http.login(this.client.email, this.client.password, this.client.client_id).subscribe(success => {
      success = success;
      let token = success && success.access_token,
        center_id = success && success.center_id,
        client_id = success && success.logged_user.uuid,
        businessName = success && success.client_name,
        // currency = success && success.currency,
        // country = success && success.country,
        // name = success && success.logged_user.first_name + " " + success.logged_user.last_name,
        name = success && success.logged_user.first_name,

        setup_completed = success && success.logged_user.setup_completed,
        setup_details_completed = success && success.logged_user.setup_details_completed,
        onboarding_completed = success && success.logged_user.onboarding_completed;
      // phone = success && success.logged_user.phone;

      var role = null;
      if (success.logged_user.is_super_admin == 0) {
        role = JSON.stringify(success.logged_user.roles);
      }
      var localizedData = success.localization;
      localStorage.setItem("localization", JSON.stringify(localizedData));
      localStorage.setItem("currentUser", JSON.stringify({
        appId: 3,
        email: this.client.email,
        token: token,
        center_id: center_id,
        name: name,
        client_id: client_id,
        roles: role,
        businessName: businessName,
        setup_completed: setup_completed,
        onboarding_completed: onboarding_completed,
        setup_details_completed: setup_details_completed,
        intercomSecret: success.logged_user.secret,
        is_verified: success.logged_user.is_verified
      }));
      this.loaderOn = true;
      this.initializePostHog()

      if (onboarding_completed == true) {
        if (this.generalFunctions.checkDevice()) {
          this.router.navigate(["client/mobile"]);
        } else {
          // this.router.navigate(["client/dashboard"]);
          this.getRegion();
        }
      } else {
        this.router.navigate(["client/create-account"]);
      }
    }, err => {
      // if (error.error.errors.email[0]) {
      //   this.error.msg = error.error.errors.email[0];
      // }
      this.error.msg = err.message;
      this.loaderOn = false;
      this.error.throw = 2;
    });
  }

  getRegion() {
    this.router.navigate(["client/region"]);
  }

  forgotPassword() {
    if (!this.validationFields.reset_email) {
      this.generalFunctions.openToast("Please fill in required fields", 5000, "error");
      return;
    }
    this.http.resetPasswordLink(this.reset).subscribe(success => {
      var msg = "Password reset email successfully sent!";
      this.generalFunctions.openToast(msg, 5000, "success");
      this.resetOn = false;
      this.error.throw = 1;
      this.error.msg = "You will receive an email with instructions about how to reset your password in a few minutes";
      this.removeInvalidParams()
    }, error => {
      this.resetOn = false;
      this.error.throw = 1;
      this.actualError = true;
      this.error.msg = "You will receive an email with instructions about how to reset your password in a few minutes";
      this.removeInvalidParams()

    });
  }

  removeInvalidParams() {
    this.route.queryParams.subscribe(params => {
      if (params["invalidToken"]) {
        this.router.navigate([], {
          queryParams: {
            'invalidToken': null,
          },
          queryParamsHandling: 'merge'
        })
      }
    })
  }

  formValidate(field) {
    var isValid = true;
    switch (field) {
      case "customer-email":
        isValid = !this.validateEmail(this.client.email)
          ? false
          : true;
        this.validationFields.customer_email = isValid;
        break;

      case "customer-password":
        let pass = this.client.password;
        isValid = pass.length >= 6
          ? true
          : false;
        this.validationFields.customer_password = isValid;
        break;

      case "reset-email":
        isValid = !this.validateReset(this.reset.email)
          ? false
          : true;
        this.validationFields.reset_email = isValid;
        break;

      default:
        break;
    }
    return !isValid;
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  validateReset(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  @debounce(400)
  fetchResults(e) {
    if (this.header_search.length > 1) {
      this.searchFilter["filterBy"]["query"] = this.header_search;
      const encodedQuery = encodeURIComponent(this.searchFilter["filterBy"]["query"])
      this.http.searchClient("clients?query=" + encodedQuery).subscribe(success => {
        this.client_results = success.results;
        var timeout = setTimeout(() => {
          if (!this.header_results_ul) {
            return false;
          }
          var ul = this.header_results_ul.nativeElement;
          var els = ul.getElementsByTagName("li");
          if (this.client_results.length > 0) {
            var firstel = els[0];
            firstel.classList.add("focused");
          }
          clearTimeout(timeout);
        }, 10);
      }, err => {
        if(err.status === 503){
          this.showMaintanceBanner = true;
        }
        else{
          this.showMaintanceBanner = false;
          this.generalFunctions.openToast(err?.message, 3000, "error");
        }
      });
    }
  }

  changeHoverResults(e) {
    if (this.header_search.length < 2 || !this.header_results_ul) {
      return false;
    }
    var key = e.keyCode;
    var ul = this.header_results_ul.nativeElement;
    var els = ul.getElementsByTagName("li");
    var focusedel = ul.getElementsByClassName("focused")[0];

    if (key == 39 || key == 40) {
      if (focusedel) {
        var j = this.generalFunctions.index(focusedel);
        if (els.length > j + 1) {
          focusedel.classList.remove("focused");
          els[j + 1].classList.add("focused");
          var newfocusedel = ul.getElementsByClassName("focused")[0];
          if (ul.offsetHeight < newfocusedel.offsetTop) {
            ul.scrollTop = newfocusedel.offsetTop - 238;
          }
        }
      }
    } else if (key == 37 || key == 38) {
      if (focusedel) {
        var j = this.generalFunctions.index(focusedel);
        if (j + 1 > 1) {
          focusedel.classList.remove("focused");
          els[j - 1].classList.add("focused");
          var newfocusedel = ul.getElementsByClassName("focused")[0];
          ul.scrollTop = newfocusedel.offsetTop - 22;
        }
      }
    } else if (key == 13) {
      focusedel.dispatchEvent(new Event("click"));
    }
  }
  focusResult(e) {
    var el = e.target.nodeName == "LI"
      ? e.target
      : this.generalFunctions.closestTag(e.target, "LI");
    if (!el.classList.contains("focused")) {
      var ul = this.header_results_ul.nativeElement;
      var els = ul.getElementsByTagName("li");
      var focusedel = ul.getElementsByClassName("focused")[0];
      if (focusedel) {
        focusedel.classList.remove("focused");
        el.classList.add("focused");
      }
    }
  }

  setId(client) {
    this.selectedClient = client;
    // localStorage.setItem("currentUser", JSON.stringify({}));
    let structure = {
      image_url: client.image_url,
      name: client.name,
      client_url: client.url_prefix,
      client_id: client.uuid
    };
    localStorage.setItem("client", JSON.stringify(structure));
    this.firstPhase = false;
  }
}
